@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

@tailwind base;
@tailwind components;

.box.arrow-top {
  margin-top: 40px;
}

.box.arrow-top:after {
  content: " ";
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid black;
}

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/theme.scss';

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
    .rtl {
        direction: rtl;
    }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
    display: none;
}

// AOS styles
$aos-distance: 10px;
@import 'node_modules/aos/src/sass/aos.scss';

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.some-page-wrapper {
  margin: auto;
  display: flex;
}

#tsparticles {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
}


