html {
    scroll-behavior: smooth;
    box-sizing: border-box;
}

:focus,
button:focus,
.btn:focus,
.btn-sm:focus {
    outline: 3px solid rgba(#7779FC, 0.5);
}

#ip1 {
  border-radius: 18px;
  padding: 20px; 
  width: 200px;
  height: 15px;    
}


// Hamburger button
.hamburger {

    svg > * {

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            transform-origin: center;
            -webkit-transform-origin: center;
        }

        &:nth-child(1) {
            transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
            -webkit-transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
        }

        &:nth-child(2) {
            transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            -webkit-transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &:nth-child(3) {
            transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
            -webkit-transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
        }        
    }

    &.active {

        svg > * {

            &:nth-child(1) {
                opacity: 0;
                y: 11;
                transform: rotate(225deg);
                transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out;
                -webkit-transform: rotate(225deg);
                -webkit-transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out;
            }
    
            &:nth-child(2) {
                transform: rotate(225deg);
                transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
                -webkit-transform: rotate(225deg);
                -webkit-transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
    
            &:nth-child(3) {
                y: 11;
                transform: rotate(135deg);
                transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;   
                -webkit-transform: rotate(135deg);
                -webkit-transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;   
            }
        }
    }
}

.blur {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

// Pulsing animation
@keyframes pulseLoop {
    0% { opacity: .15; transform: scale(1) translateZ(0); }
    30% { opacity: .15; }
    60% { opacity: 0; }
    80% { opacity: 0; transform: scale(1.8) translateZ(0); }
}
@keyframes pulseMiniLoop {
    0% { opacity: 0; transform: scale(1) translateZ(0); }
    30% { opacity: .3; }
    50% { opacity: .3; }
    80% { opacity: 0; transform: scale(3) translateZ(0); }
}
.pulse {
    transform: scale(1);
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 10000ms linear infinite;                                   
}
.pulse-mini {
    animation: pulseMiniLoop 6000ms linear infinite;                                   
}
.pulse-1 {
    animation-delay: -3000ms;
}
.pulse-2 {
    animation-delay: -6000ms;
}

// Animations delay
.animation-delay-500 {
    animation-delay: 500ms !important;
}

.animation-delay-1000 {
    animation-delay: 1000ms !important;
}

.translate-z-0 {
    transform: translateZ(0);
}

// Custom AOS animations
[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}
html,
body {
  margin: 0;
}

.banner {
  background: #7779FC;
}

.banner__content {
  padding: 16px;
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.banner__text {
  flex-grow: 1;
  line-height: 1.4;
  font-family: "Quicksand", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

.w3-container {
  position: sticky;
  margin: auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 10rem;
  background-color: #fafafa00;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 75%;
  max-width: 1080px;
  height:auto;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #7779FC;
  position: relative;
  height: auto;
}

.circle {
  border-radius: 50%;
  margin: auto;
  background: linear-gradient(135deg, transparent 20%, #9a9cff);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #7779FC;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.6rem 2.2rem;
  z-index: 10;
  margin: auto;
  overflow: hidden;
  position: sticky;
  margin-top: auto;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

.textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn {
  padding: 1rem 1.3rem;
  background-color: #fff;
  border: 3px solid #fafafa;
  font-size: 0.95rem;
  color: #7779FC;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.btn:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #7779FC;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #7779FC;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.fa {
  padding: 10px;
  display: flex;
  font-size: 100px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 10px 3px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  color: rgb(0, 0, 0);
}

.fa-twitter {
  color: rgb(0, 0, 0);
}

.fa-linkedin {
  color: rgb(0, 0, 0);
}
.fa-instagram {
  color: rgb(0, 0, 0);
}
.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #7779FC;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #7779FC, #9a9cff);
  bottom: 45%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }

  .responsive {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .center {
    display: block;
    margin-left: auto;
    margin: auto;
    margin-right: auto;
    width: 100%;
  }

  .some-page-wrapper {
    margin: auto;
  }
  




}